.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Chiều cao toàn màn hình */
    width: 100vw; /* Chiều rộng toàn màn hình */
    margin: 0; /* Loại bỏ margin mặc định */
    overflow: hidden; /* Ẩn thanh trượt */
    background: url('./assets/images/home-bg.png') no-repeat top center; /* Hình nền */
    background-size: cover; /* Tự động vừa khít màn hình */
    position: relative;
  }
  
  .input-container {
    position: relative;
    z-index: 10; /* Đặt lên trên hình nền */
    text-align: center;
    margin-top: 100px;
    background-color: rgba(0, 0, 0, 0.1); /* Nền mờ đen */
    padding: 20px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 150px;
    margin-right: 10px;
  }

  input.custom-input {
    background: none; /* Xóa nền */
    border: none; /* Xóa viền mặc định */
    border-bottom: 2px solid #ccc; /* Thêm đường gạch phía dưới */
    padding: 5px 10px; /* Thêm khoảng cách bên trong */
    font-size: 16px; /* Kích thước chữ */
    color: #333; /* Màu chữ */
    outline: none; /* Xóa viền mặc định khi focus */
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  