.f3 {
  height: 900px;
  max-height: calc(100vh - 0px);
  width: 900px;
  max-width: 100%;
  margin: auto;
  position: relative;
}

.f3 .cursor-pointer {
  cursor: pointer;
}
.home svg.main_svg {
  width: 100%;
  height: 100vh;
  background: url('./assets/images/bg.png') no-repeat top center; /* Webpack xử lý */
  background-size: cover;
}
.create svg.main_svg {
  width: 100%;
  height: 100%;
  background-color: #3b5560;
  color: #3b5560;
}
.f3 svg.main_svg text {
  fill: currentColor;
}
.f3 rect.card-female,
.f3 .card-female .card-body-rect,
.f3 .card-female .text-overflow-mask {
  fill: lightpink;
}
.f3 rect.card-male,
.f3 .card-male .card-body-rect,
.f3 .card-male .text-overflow-mask {
  fill: lightblue;
}
.f3 .card-genderless .card-body-rect,
.f3 .card-genderless .text-overflow-mask {
  fill: lightgray;
}
.f3 .card_add .card-body-rect {
  fill: #3b5560;
  stroke-width: 4px;
  stroke: #fff;
  cursor: pointer;
}
.f3 g.card_add text {
  fill: #fff;
}
.f3 .card-main {
  stroke: #000;
}
.f3 .card_family_tree rect {
  transition: 0.3s;
}
.f3 .card_family_tree:hover rect {
  transform: scale(1.1);
}
.f3 .card_add_relative {
  cursor: pointer;
  color: #ffffff;
  transition: 0.3s;
}
.f3 .card_add_relative circle {
  fill: rgba(0, 0, 0, 0);
}
.f3 .card_add_relative:hover {
  color: black;
}
.f3 .card_edit.pencil_icon {
  color: #fff;
  transition: 0.3s;
}
.f3 .card_edit.pencil_icon:hover {
  color: black;
}
.f3 .card_break_link,
.f3 .link_upper,
.f3 .link_lower,
.f3 .link_particles {
  transform-origin: 50% 50%;
  transition: 1s;
}
.f3 .card_break_link {
  color: #fff;
}
.f3 .card_break_link.closed .link_upper {
  transform: translate(-140.5px, 655.6px);
}
.f3 .card_break_link.closed .link_upper g {
  transform: rotate(-58deg);
}
.f3 .card_break_link.closed .link_particles {
  transform: scale(0);
}
.f3 .input-field input {
  height: 2.5rem !important;
}
.f3 .input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-8px) scale(0.8);
  transform: translateY(-8px) scale(0.8);
}
.search-input::placeholder {
  color: red;
}
/* path.link { stroke: hsl(47, 90%, 49%) !important; } */


.f3 div.cardd {
  cursor: pointer;
  color: #fff;
  position: relative;
}

.f3 div.card-image {
  border-radius: 50%;
  padding: 5px;
  width: 90px;
  height: 90px;
}

.f3 div.card-image div.card-label {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 50%);
  max-width: 170%;
  height: 22px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  padding: 0 5px;
}

.f3 div.card-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.f3 div.card-text {
  padding: 5px;
  border-radius: 3px;
  width: 120px;
  height: 70px;
  overflow: hidden;
  line-height: 1.2;
}

.f3 div.cardd > div {
  transition: transform 0.2s ease-in-out;
}

.f3 div.cardd:hover > div {
  transform: scale(1.1);
}
.f3 div.card-female {
  background-color: hsl(338, 80%, 73%);
}
.f3 div.card-male {
  background-color: hwb(160 34% 35%);
}
.f3 div.card-genderless {
  background-color: lightgray;
}
.f3 div.card-main {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
}

.hamburger-menu {
  display: none;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
}

/* Khi màn hình nhỏ */
@media screen and (max-width: 768px) {
  .search-input {
    display: none;
  }

  .reset-btn {
    display: none;
  }

  .image-btn {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .search-container {
    left: 5px !important;
  }

  .reset-btn {
    right: 5px !important;
    top: 50px !important;
  }

  .image-btn {
    right: 5px !important;
  }
}